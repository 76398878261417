import React from "react";
const LOGO = (
  <svg viewBox="0 0 531.951 1042.92" className="lg:w-32 w-28">
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#eeac5a" />
        <stop offset="0.3" stopColor="#eb733a" />
        <stop offset="0.622" stopColor="#e93d1c" />
        <stop offset="0.864" stopColor="#e81b09" />
        <stop offset="0.994" stopColor="#e70e02" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.08"
        y1="0.789"
        x2="1.021"
        y2="0.243"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e89005" />
        <stop offset="1" stopColor="#ec7505" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="0.053"
        y1="0.238"
        x2="0.987"
        y2="0.759"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f42b03" />
        <stop offset="1" stopColor="#d84a05" />
      </linearGradient>
    </defs>
    <g id="Layer_7" transform="translate(-1351.569 -175.32)">
      <g id="Layer_3">
        <path id="Path_1" d="M1844.64,1218.24h0Z" fill="none" />
        <path
          id="Path_2"
          d="M1553.75,351.49c1.05.06,2.09.11,3.13.17V175.32h0a72.364,72.364,0,0,0-72.36,72.36V357.24C1500.8,353.01,1534.36,350.37,1553.75,351.49Z"
          fill="#eeac5a"
        />
        <path
          id="Path_3"
          d="M1653.6,350.05V175.32h0a72.364,72.364,0,0,0-72.36,72.36V352.62C1602.49,352.99,1634.76,351.51,1653.6,350.05Z"
          fill="#e70e02"
        />
        <path
          id="Path_4"
          d="M1635.6,943.5v100.38a72.381,72.381,0,0,1-72.36,72.36V948.63h.02l72.32-5.13Z"
          fill="#ec7505"
        />
        <path
          id="Path_5"
          d="M1538.88,947.12v95.8a72.381,72.381,0,0,1-72.36,72.36V946.26c8.17-.47,16.63-.72,25.32-.67q5.9.03,11.92.24C1515.66,946.26,1527.38,946.75,1538.88,947.12Z"
          fill="#e89005"
        />
        <path
          id="Path_6"
          d="M1712.94,454.58c-18.22,14.92-50.14,19.22-84.26,20.18-33.83.95-69.8-1.37-96.7.12-37.84,2.09-87.91,40.02-62.78,82.9,3.96,6.75,10.09,11.94,16.44,16.5,6.71,4.82,13.9,8.98,22.1,10.58.07.01.13.03.2.04-.41.01-.82.01-1.22.01q-9.7.09-18.85-.38-12.42-.615-23.88-2.31c-.39-.06-.78-.12-1.16-.18-20.29-3.13-38.4-8.98-54.7-18.25-.09-.06-.19-.11-.28-.17-.78-.44-1.56-.89-2.33-1.35a136.2,136.2,0,0,1-11.78-7.92c-.36-.27-.72-.54-1.07-.81a107.712,107.712,0,0,1-10.18-8.87q-.63-.615-1.23-1.23a121.116,121.116,0,0,1-21.18-29.47c-.14-.26-.27-.52-.39-.77a86,86,0,0,1-4.09-9.34c-.02-.07-.05-.13-.07-.19,0,0,.28-1.45.77-3.22l.44-1.61c.21-.78.43-1.56.65-2.33,23.29-82.15,81.26-140,198.88-133.69a756.879,756.879,0,0,0,120.8-3.55c57.11-6.15,94.72-16.36,94.72-16.36C1774.31,375.34,1756.32,419.04,1712.94,454.58Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_7"
          d="M1764.32,787.03q-.555,4.065-1.21,7.95c-8.31,49.12-28.56,76.86-45.69,95.28-51.78,55.68-128.01,49.69-213.66,46.57q-6.03-.21-11.92-.24c-79.83-.44-140.27,23.74-140.27,23.74-.21-45.37,29.01-83.2,29.01-83.2,38.92-55.69,88.71-58.91,132.19-56.61h.01c16.2.85,31.52,2.47,45.07,2.43,49.94-.16,98.24,14.06,101.97-61.5,1.63-33.06-18.51-48.65-47.63-56.8C1711.23,702.67,1750.18,756.38,1764.32,787.03Z"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_8"
          d="M1764.32,787.03c1.36-9.19,2.63-29.79,2.56-39.56-.37-46.92-21.34-91.66-58.71-120.6a154.446,154.446,0,0,0-90.31-31.81c-35.99-.83-75.15,2.19-109.91-10.16-112.44,2.25-143.62-58.4-152.41-81.22-20.16,87.38,61.42,173.56,126.89,185.22,40.02,7.13,92.32,5.29,129.77,15.76C1711.23,702.67,1750.18,756.38,1764.32,787.03Z"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_9"
          d="M1487.87,584.53c-37.86-1.88-68.89-10.97-94.93-30.78-27.64-21.04-37.41-50.08-37.41-50.08s.28-1.45.77-3.22l.44-1.61c.21-.78,1.62-5.63,1.84-6.4.4.63,9.63,30.8,37.14,54.04C1417.65,565,1452.3,581.25,1487.87,584.53Z"
          opacity="0.15"
        />
        <path
          id="Path_10"
          d="M1764.32,787.03q-.555,4.065-1.21,7.95c-.78,4.39-.34,1.95-.34,1.95-15.9-30.25-37.98-53.64-63.81-67.57-41.52-22.38-86.77-24.71-86.77-24.71C1711.23,702.67,1750.18,756.38,1764.32,787.03Z"
          opacity="0.15"
        />
        <path
          id="Path_11"
          d="M1712.94,454.58c-18.22,14.92-50.14,19.22-84.26,20.18,52.01-47.82,52.36-93.48,48.39-115.49,57.11-6.15,94.72-16.36,94.72-16.36C1774.31,375.34,1756.32,419.04,1712.94,454.58Z"
          fill="#fff"
          opacity="0.41"
        />
        <path
          id="Path_12"
          d="M1491.84,936.58v.01c-79.83-.44-140.27,23.74-140.27,23.74-.21-45.37,29.01-83.2,29.01-83.2,38.92-55.69,88.71-58.91,132.19-56.61C1446.59,877.84,1491.84,936.58,1491.84,936.58Z"
          fill="#fff"
          opacity="0.4"
        />
      </g>
    </g>
  </svg>
);

export default LOGO;
